<template>
  <div id="term-create-details-tab" @keyup.ctrl.enter="term_update_data">
    <div class="vx-col w-full">
      <div class="vx-row">
        <div class="vx-col w-full mb-6">
          <vs-input v-model="dataLocal.id" class="w-full" label-placeholder="Term ID" readonly />
        </div>
        <div class="vx-col w-1/2">
          <h2 class="font-light mb-2 ml-1">Term</h2>
          <vs-textarea v-model="dataLocal.term" height="200px" />
        </div>
        <div class="vx-col w-1/2">
          <h2 class="font-light mb-2 ml-1">Definition</h2>
          <vs-textarea v-model="dataLocal.definition" height="200px" />
        </div>
        <div class="vx-col w-1/2">
          <h2 class="font-light mb-2 ml-1">Term Extended</h2>
          <vs-textarea v-model="dataLocal.term_extended" height="200px" />
        </div>
        <div class="vx-col w-1/2">
          <h2 class="font-light mb-2 ml-1">Definition Extended</h2>
          <vs-textarea v-model="dataLocal.definition_extended" height="200px" />
        </div>
        <div class="vx-col w-full mb-6">
          <h2 class="font-light mb-2 ml-1">Term Image URL</h2>
          <vs-input class="w-full" v-model="dataLocal.term_image" />
        </div>
        <div class="vx-col w-full">
          <h2 class="font-light mb-2 ml-1">Definition Image URL</h2>
          <vs-input class="w-full" v-model="dataLocal.definition_image" />
        </div>
      </div>
      <div class="vx-row mb-6"></div>

      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-button class="mb-2 mr-2" color="success" type="filled" @click="term_update_data">Update Term </vs-button>
          <vs-button class="mb-2 ml-2" color="warning" type="border" @click="reset_data">Reset </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      term_description_counter: null,

      statusOptions: ['active', 'hidden', 'disabled'],
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    term_update_data() {
      this.$emit('update_termData', this.dataLocal);
    },
    reset_data() {
      this.dataLocal = JSON.parse(JSON.stringify(this.data));
    },
  },
};
</script>

<style scoped></style>
