<!-- =========================================================================================
  File Name: EditDomain.vue
  Description: User Edit Page

========================================================================================== -->

<template>
  <div id="page-user-edit">

    <vs-alert color="danger" title="Term Not Found" :active.sync="errorOccurred">
      <span>Term record with id: {{ $route.params.termId }} not found. </span>
      <span>
        <span>Check </span><router-link :to="{name:'admin-list-content-term'}"
                                        class="text-inherit underline">All Terms</router-link>
      </span>
    </vs-alert>

    <vx-card v-if="termData">

      <div slot="no-body" class="tabs-container px-6 pt-6">

        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Details" icon-pack="feather" icon="icon-edit">
            <div class="tab-text">
              <term-details-tab class="mt-4" :data="termData" v-on:update_termData="update_termData"/>
            </div>
          </vs-tab>
        </vs-tabs>

      </div>

    </vx-card>
  </div>
</template>

<script>
import TermDetailsTab from './TermDetailsTab.vue'


export default {
  components: {
    TermDetailsTab
  },
  data () {
    return {
      termData: null,
      errorOccurred: false,

      activeTab: 0
    }
  },
  methods: {
    loadTermData () {
      this.$http.get(`flashcards/terms/${this.$route.params.termId}`)
        .then(response => {
          if (response.status === 200) {
            this.termData = response.data.data
          }

        })
        .catch(error => {
          this.errorOccurred = true
          this.error_msg = error
        })
    },
    update_termData (data) {
      const pl = data

      if (pl.term === undefined || pl.definition === undefined) {
        return this.$vs.notify({
          title: 'Failed to update term',
          text: 'Please provide a Term Name',
          color: 'danger',
          position: 'top-right'
        })
      }

      this.$vs.loading()

      this.$http.post(`flashcards/terms/${this.$route.params.termId}`, pl)
        .then(response => {

          if (response.status === 200) {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Updated Term Successfully',
              text: 'Term has been updated.',
              color: 'success',
              position: 'top-right'
            })
            return this.loadTermData()
          }

        })
        .catch(exception => {
          this.$vs.loading.close()

          let error = 'An unknown error occurred while updating this term'
          if (exception.response) {
            error = exception.response.data.error.description
          }

          return this.$vs.notify({
            title: 'Failed to update term',
            text: error,
            color: 'danger',
            position: 'top-right'
          })
        })
    }
  },
  created () {
    this.loadTermData(this.$route.params.termId)
  }
}

</script>
